<template>
  <div>

    <div class="row align-items-center justify-content-end mt-n3">
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input type="text" v-model="filter.name" placeholder="Cari Berdasar Nama"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>
    <b-table striped hover class="mt-3" responsive :items="items" :fields="fields">
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'actions' ? '20%' : '' }" />
      </template>
      <template #cell(price)="data">
        <div><b>{{ parseInt(data.item.price - data.item.promo_amount).toLocaleString("id-ID") }}</b></div>
        <span v-if="data.item.promo">
          <b-button size="sm" class="p-1 mr-1 bg-light-danger text-danger font-weight-bolder">
            - {{ getPromoInfo(data.item) }}
          </b-button>
          <small><s>{{ parseInt(data.item.price).toLocaleString('id-ID') }}</s></small>
        </span>
      </template>
      <template #cell(doctor_commission)="data">
        {{ data.item.doctor_commission }}
      </template>
      <template #cell(hospital_income)="data">
        {{ data.item.hospital_income }}
      </template>
      <template #cell(actions)='data'>
        <div class="d-flex">
          <template v-if="purpose == 'master'">
            <b-button size="sm" class="mr-1 btn-primary" variant="primary" v-b-tooltip.hover title="Komisi Dokter"
              @click="$router.push({ path: '/payment-category/add-doctor-commision/' + data.item.id })"><i
                class="fas fa-user-md px-0"></i></b-button>
            <b-button size="sm" class="mr-1 btn-info" v-b-tooltip.hover title="Detail"
              @click="$router.push({ path: '/payment-category/detail/' + data.item.id })"><i
                class="fas fa-eye px-0"></i></b-button>
            <b-button size="sm" class="mr-1 btn-success" v-b-tooltip.hover title="Edit" v-if="btn"
              @click="$router.push({ path: '/payment-category/edit/' + data.item.id })"><i
                class="fas fa-edit px-0"></i></b-button>
            <b-button size="sm" class="btn-danger" v-b-tooltip.hover title="Hapus" v-if="btn"
              @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
          </template>
          <template v-if="purpose == 'modal'">
            <!-- <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="chooseItem({id: data.item.id, name: data.item.name, price: data.item.price})"
          >Pilih</b-button> -->
            <b-form-checkbox :checked="data.item.checked" :id="`checkbox-pick-payment-category-${data.item.id}`"
              :name="`checkbox-pick-payment-category-${data.item.id}`"
              @change="chooseItem(data.item)">
            </b-form-checkbox>
          </template>
        </div>
      </template>
    </b-table>
    <b-pagination v-if="items.length != 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
      aria-controls="my-table" @page-click="pageOnClick" class="mt-4"></b-pagination>

  </div>
</template>

<script>

import { debounce, getPromoAmount } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    purpose: String,
    selectedItem: Array
  },

  data() {
    return {
      // Filter
      filter: {
        name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi Dokter",
          sortable: true,
        },
        {
          key: "hospital_income",
          label: "Pendapatan Rumah Sakit",
          sortable: true,
          thClass: 'text-truncate',
          thStyle: { maxWidth: '90px', overflowX: 'auto' }
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // User Access
      btn: false,
      btnAddDoctorCommission: false
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    chooseItem(value) {
      console.info('selected-item', value)
      this.$emit("chooseItem", value)
    },

    getPromoInfo(item) {
      let result = ``
      if (item.promo) {
        if (item.promo.is_percent == 1)
          result = `${parseInt(item.promo.amount)}%, ${parseInt(item.price * item.promo.amount / 100).toLocaleString('id-ID')}`
        else
          result = parseInt(item.promo.amount).toLocaleString('id-ID')
      }
      return result
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate(
        'payment-categories',
        `?page=${this.currentPage}&with_promo=true&page_size=${this.perPage}${filterParams}`
      )
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        let row = response.data[a]
        // add number
        row.number = b + a
        // add others
        row.label = row.name
        row.const_price = row.price
        row.discount_percentage = 0
        row.discount_amount = 0
        row.discount_type = 0
        row.is_bundle = row.isBundle
        row.is_deposit = 0
        row.quantity = 1
        row.promo_amount = getPromoAmount(row.promo, row.const_price)
      }
      this.items = response.data
      this.setCheckedItem()
    },

    setCheckedItem() {
      if (typeof this.selectedItem != 'undefined' && this.selectedItem != null) {
        let found
        this.items.forEach(value => {
          found = this.selectedItem.find(x => x.id == value.id)
          if (typeof found === 'undefined') {
            value.checked = false
          } else {
            value.checked = true
          }
        })
      }
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('payment-categories/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6009") {
          this.btn = true
        }
        if (access_right[a] == "6023") {
          this.btnAddDoctorCommission = true
        }
      }
    },

  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem()
    }
  },

  mounted() {
    // Get Data
    this.pagination()
    this.setActiveButton()
  }

}
</script>

<style></style>